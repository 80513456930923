<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-form style="display: flex" label-position="left">
          <el-form-item style="margin-top: 0">
            <el-select></el-select>
          </el-form-item>
          <el-form-item style="margin-top: 0">
            <el-select></el-select>
          </el-form-item>
          <el-form-item label="商品：" label-width="60px" style="margin-top: 0">
            <el-input />
          </el-form-item>
          <el-form-item>
            <el-checkbox label="零库存" />
          </el-form-item>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-form>
      </div>
    </div>
    <el-table ref="multipleTable">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="仓库"></el-table-column>
      <el-table-column label="商品类别"></el-table-column>
      <el-table-column label="商品编号"></el-table-column>
      <el-table-column label="商品名称"></el-table-column>
      <el-table-column label="规格型号"></el-table-column>
      <el-table-column label="单位"></el-table-column>
      <el-table-column label="系统库存"></el-table-column>
      <el-table-column label="盘点库存"></el-table-column>
      <el-table-column label="盘盈盘亏"></el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination
        layout="prev, pager, next"
        :page-size="search['per-page']"
        :current-page.sync="search.page"
        :total="pageCount"
        @current-change="pageChangeHandle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "stockInquiry",
  data() {
    return {
      multipleTable: [],
      pageCount: 0,
      search: {
        "per-page": 10,
        page: 1,
      },
    };
  },
};
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
</style>
